import { useState, useEffect, Fragment } from 'react';
import { useStateValue } from '../../StateProvider';
import './NewReportApiTest.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import MainAppContentArea from '../../components/mainAppContentArea/MainAppContentArea';
import PageHeadline from '../../components/pageHeadline/PageHeadline';
import {
	GetAccountBalance,
	GetApiStatus,
	GetBusinessInfoLive,
	GetBusinessInfoTaskPost,
	GetBusinessProfilePosts,
	GetGoogleProfileReviews,
	GetBusinessProfilePhotos,
	GetBusinessProfileReviews,
	GetBusinessProfileDetails,
	GetBusinessProfileQA,
	GetOnPageSEO,
	GetDSEOTrendsExplore,
	GetGoogleSearchResults,
	GetLocationsByCountry,
	GetDomainAuthority,
	GetPageSpeed,
	GetKeywordsForSite,
	GetKeywordsSearchVolume,
	GetDomainWhoIs
} from '../../ReportAPI';
import ReactJsonView from '@microlink/react-json-view';
import Select from 'react-select';
import { GetCampaign, GetCampaignsForSelect } from '../../Services';
import { GetAccountData } from '../../ReportAPI';
import { GetBacklinksSummary } from '../../ReportAPI';
import GoogleBusinessAutoComplete from '../../components/googleBusinessAutoComplete/GoogleBusinessAutoComplete';
import Pagination from '../../components/pagination/Pagination';
import Spinner from '../../components/spinner/Spinner';
import { parseAddressFromGoogleProfile } from '../../utils/Common';

const NewReportApiTest = () => {
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ { user, host }, dispatch ] = useStateValue();
	const [ methodTitle, setMethodTitle ] = useState('');
	const [ placeId, setPlaceId ] = useState('');
	const [ campaigns, setCampaigns ] = useState();
	const [ selectedCampaign, setSelectedCampaign ] = useState();
	const [ selectedCampaignData, setSelectedCampaignData ] = useState();
	const [ keywords, setKeywords ] = useState();
	const [ locations, setLocations ] = useState();
	const [ selectedLocation, setSelectedLocation ] = useState();
	const [ onPageTarget, setOnPageTarget ] = useState('');
	const [ onPageStartUrl, setOnPageStartUrl ] = useState('');
	const [ onPageMaxCrawlPages, setOnPageMaxCrawlPages ] = useState('');
	const [ searchTerm, setSearchTerm ] = useState('');
	const [ searchTermLabel, setSearchTermLabel ] = useState('Search Term');
	const [ apiResponse, setApiResponse ] = useState('');
	const [ selectedApiMethod, setSelectedApiMethod ] = useState();
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ totalResults, setTotalResults ] = useState();
	const [ pageSize, setPageSize ] = useState();
	const [ businessUrl, setBusinessUrl ] = useState();
	const dataForSeoApiMethods = [
		{
			value         : 'GetBusinessProfileInfoStandard',
			label         : 'Get Business Profile Info (Standard)',
			showCampaign  : true,
			showPlaceId   : true,
			showLocations : true
		},
		{
			value         : 'GetBusinessProfileInfoLive',
			label         : 'Get Business Profile Info (Live)',
			showCampaign  : true,
			showPlaceId   : true,
			showLocations : true
		},
		{
			value         : 'GetBusinessProfilePosts',
			label         : 'Get Business Profile Posts',
			showCampaign  : true,
			showPlaceId   : true,
			showLocations : true
		},
		{
			value         : 'GetBusinessProfileQA',
			label         : 'Get Business Profile Questions & Answers',
			showCampaign  : true,
			showPlaceId   : true,
			showLocations : true
		},
		{
			value        : 'GetBacklinksSummary',
			label        : 'Get Backlinks Summary',
			showCampaign : true
			// showBacklinksParams : true
		},
		{
			value        : 'GetDSEOTrendsExplore',
			label        : 'Get DataForSEO Trends Explore',
			showCampaign : true
			// showBacklinksParams : true
		},
		{
			value          : 'GetKeywordsForSite',
			label          : 'Get Keywords For Site',
			showCampaign   : true,
			showSearchTerm : true
		},
		{
			value        : 'GetKeywordsSearchVolume',
			label        : 'Get Keywords Search Volume',
			showCampaign : true,
			showKeywords : true
			//showLocations : true
		},
		{ value: 'GetOnPageSEO', label: 'Get Page SEO Results', showCampaign: true, showDSEOOnPageParams: true },
		{ value: 'GetApiStatus', label: 'Get API Status', showPlaceId: false },
		{ value: 'GetAccountBalance', label: 'Get Balance', showPlaceId: false }
	];
	const serpApiMethods = [
		{
			value        : 'GetBusinessProfileDetails',
			label        : 'Get Business Profile Details',
			showCampaign : true,
			showPlaceId  : true
		},
		{
			value        : 'GetBusinessProfileReviews',
			label        : 'Get Business Profile Reviews',
			showCampaign : true,
			showPlaceId  : true
		},
		{
			value        : 'GetBusinessProfilePhotos',
			label        : 'Get Business Profile Photos',
			showCampaign : true,
			showPlaceId  : true
		},
		{
			value          : 'GetGoogleSearchCitations',
			label          : 'Get Google Citations',
			showCampaign   : true,
			showSearchTerm : true
		},
		{
			value          : 'GetGoogleSearchIndexedPagesByKeyword',
			label          : 'Get Google Indexed Pages By Keyword',
			showCampaign   : true,
			showSearchTerm : true
		},
		{
			value          : 'GetGoogleSearchAllIndexedPages',
			label          : 'Get Google All Indexed Pages',
			showCampaign   : true,
			showSearchTerm : true
		},
		{
			value : 'GetAccountData',
			label : 'Get Account Data'
		}
	];
	const rapidApiMethods = [
		{
			value          : 'GetDomainAuthority',
			label          : 'Get Domain Age And Authority',
			showCampaign   : true,
			showSearchTerm : true
		},
		{
			value          : 'GetDomainWhoIs',
			label          : 'Get Domain WhoIs',
			showCampaign   : true,
			showSearchTerm : true
		}
	];
	const googleApiMethods = [
		{
			value          : 'GetPageSpeed',
			label          : 'Get Page Speed',
			showCampaign   : true,
			showSearchTerm : true
		}
	];
	const emptySelectItem = {
		value : null,
		label : ''
	};
	const emptyApiResponse = {
		response : 'No items found'
	};

	useEffect(() => {
		getCampaigns();
		// getLocations();
	}, []);

	const getCampaigns = async () => {
		let response = await GetCampaignsForSelect();

		if (response && response.length) {
			//let tempCampaigns = response.filter((x) => x.status === 'Active');
			let tempCampaigns = response;
			let tempCampaignsForSelect = [];
			tempCampaignsForSelect.push(emptySelectItem);

			//sort alphabetically
			tempCampaigns = tempCampaigns.sort((a, b) => a.name.localeCompare(b.name));

			for (let i = 0; i < tempCampaigns.length; i++) {
				tempCampaignsForSelect.push({
					value : tempCampaigns[i].id,
					label : `${tempCampaigns[i].name}${tempCampaigns[i].nameExtension
						? ` - ${tempCampaigns[i].nameExtension}`
						: ''}`
				});
			}

			setCampaigns(tempCampaignsForSelect);
		}
	};

	const getCampaignDetails = async (id) => {
		let response = await GetCampaign(id);

		if (response) {
			setSelectedCampaignData(response);
		}
	};

	const handleSelectApiMethod = (apiMethod) => {
		setSelectedApiMethod(apiMethod);
		setSearchTerm('');
		setApiResponse();

		switch (apiMethod.value) {
			case 'GetDomainAuthority':
			case 'GetDomainWhoIs':
				setSearchTermLabel('Domain');
				break;
			case 'GetPageSpeed':
			case 'GetKeywordsForSite':
				setSearchTermLabel('Url');
				break;
			default:
				setSearchTermLabel('Search Term');
				break;
		}
	};

	const handleCampaignSelect = (e) => {
		setSelectedCampaign(e);
		getCampaignDetails(e.value);
	};

	useEffect(
		() => {
			if (selectedApiMethod && selectedCampaignData) {
				setBusinessUrl(selectedCampaignData.googleBusinessUrl);

				let websiteArg;
				let keywordArgs;
				let website;
				let websiteHost;

				if (selectedCampaignData.website) {
					if (
						selectedCampaignData.website.indexOf('https://') > -1 ||
						selectedCampaignData.website.indexOf('http://') > -1
					) {
						website = selectedCampaignData.website.trim().replace(/\/$/g, '');
						let websiteURLObj = new URL(website);

						if (websiteURLObj) {
							websiteHost = websiteURLObj.hostname.trim().replace('www.', '').replace(/\/$/g, '');
						}
					} else {
						websiteHost = selectedCampaignData.website.trim().replace('www.', '').replace(/\/$/g, '');
					}
				}

				switch (selectedApiMethod.value) {
					case 'GetBusinessProfileInfoStandard':
					case 'GetBusinessProfileInfoLive':
					case 'GetBusinessProfilePosts':
					case 'GetBusinessProfileQA':
						setPlaceId(selectedCampaignData.googlePlaceId);
						break;

					//Serp Api --------------------------
					case 'GetBusinessProfileDetails':
					case 'GetBusinessProfileReviews':
					case 'GetBusinessProfilePhotos':
						setPlaceId(selectedCampaignData.googlePlaceId);
						break;
					case 'GetGoogleSearchCitations':
						websiteArg = websiteHost ? `"${websiteHost}"` : '';

						let phoneArg = selectedCampaignData.phone
							? ` AND ("${selectedCampaignData.phone}" | "${selectedCampaignData.phone
									.trim()
									.replace('(', '')
									.replace(')', '')
									.replaceAll(' ', '-')}")`
							: '';

						let streetAddressArg =
							selectedCampaignData.streetAddress1 && selectedCampaignData.streetAddress1.trim() !== ''
								? ` AND "${selectedCampaignData.streetAddress1.trim()}"`
								: '';

						if (websiteArg) {
							let tempSearchTerm =
								websiteArg +
								phoneArg +
								streetAddressArg +
								(websiteHost ? ` - inurl:${websiteHost}` : '');

							setSearchTerm(tempSearchTerm);
						}
						break;
					case 'GetGoogleSearchIndexedPagesByKeyword':
						websiteArg = `site:${website}`;

						keywordArgs =
							selectedCampaignData.keywords && selectedCampaignData.keywords.length
								? ` + "${selectedCampaignData.keywords[0].keyword}"`
								: null;

						if (websiteArg) {
							let tempSearchTerm = websiteArg + keywordArgs;

							setSearchTerm(tempSearchTerm);
						}
						break;
					case 'GetGoogleSearchAllIndexedPages':
						websiteArg = `site:${website}`;

						if (websiteArg) {
							let tempSearchTerm = websiteArg;

							setSearchTerm(tempSearchTerm);
						}
						break;
					case 'GetKeywordsForSite':
						setSearchTerm(websiteHost);
						break;
					case 'GetKeywordsSearchVolume':
						keywordArgs =
							selectedCampaignData.keywords && selectedCampaignData.keywords.length
								? selectedCampaignData.keywords.map((x) => x.keyword).join(', ')
								: '';

						if (keywordArgs) {
							setKeywords(keywordArgs);
						}
						break;

					//rapid Api ------------------------------
					case 'GetDomainAuthority':
						setSearchTerm(websiteHost);
						break;
					case 'GetDomainWhoIs':
						setSearchTerm(websiteHost);
						break;
					case 'GetPageSpeed':
						setSearchTerm(website);
						break;
				}
			}
		},
		[ selectedCampaignData, selectedApiMethod ]
	);

	const handleSubmit = async (e, page) => {
		if (selectedApiMethod) {
			handleSelectedApiMethodCall(page);
		} else {
			toast.error('API method not selected');
		}
	};

	const handleSelectedApiMethodCall = async (page) => {
		// if (selectedApiMethod.showCampaign) {
		// 	if (!selectedCampaignData || !selectedCampaignData.id) {
		// 		toast.error('Campaign is required field');
		// 		return;
		// 	}
		// }

		if (selectedApiMethod.showPlaceId) {
			if (!placeId || placeId.trim() === '') {
				toast.error('Place Id is required field');
				return;
			}
		}

		if (selectedApiMethod.showLocations) {
			if (!selectedLocation) {
				toast.error('Location is required field');
				return;
			}
		}

		if (selectedApiMethod.showDSEOOnPageParams) {
			if (!onPageTarget || onPageTarget.trim() === '') {
				toast.error('Target domain is required field');
				return;
			}

			if (!onPageMaxCrawlPages || onPageMaxCrawlPages.trim() === '') {
				toast.error('Max crawl pages is required field');
				return;
			}
		}

		if (selectedApiMethod.showSearchTerm) {
			if (!searchTerm || searchTerm.trim() === '') {
				toast.error(`${searchTermLabel} is required field`);
				return;
			}
		}

		if (selectedApiMethod.showKeywords) {
			if (!keywords || keywords.trim() === '') {
				toast.error(`Keywords is required field`);
				return;
			}
		}

		setIsSubmitting(true);

		let data;
		let response;

		switch (selectedApiMethod.value) {
			case 'GetBusinessProfileInfoStandard':
				data = {
					placeId      : placeId,
					locationName : selectedLocation
				};
				response = await GetBusinessInfoTaskPost(data);
				break;
			case 'GetBusinessProfileInfoLive':
				data = {
					placeId      : placeId,
					locationName : selectedLocation
				};
				response = await GetBusinessInfoLive(data);
				break;
			case 'GetBusinessProfilePosts':
				// let tempLocation = `${selectedCampaignData.lat},${selectedCampaignData.lng},200`;
				// setSelectedLocation(tempLocation);
				data = {
					placeId : placeId
					//locationName : tempLocation
				};
				response = await GetBusinessProfilePosts(data);
				break;
			case 'GetBusinessProfileQA':
				data = {
					placeId      : placeId,
					locationName : selectedLocation
				};
				response = await GetBusinessProfileQA(data);
				break;
			case 'GetOnPageSEO':
				data = { target: onPageTarget, startUrl: onPageStartUrl };
				response = await GetOnPageSEO(data);
				break;
			case 'GetBacklinksSummary':
				if (!businessUrl || businessUrl.trim() === '') {
					toast.error('Campaign business url is empty');
					setIsSubmitting(false);
					return;
				}

				data = { target: businessUrl };
				response = await GetBacklinksSummary(data);
				break;
			case 'GetDSEOTrendsExplore':
				if (!selectedCampaignData.keywords || selectedCampaignData.keywords.length === 0) {
					toast.error('Campaign keywords not found');
					setIsSubmitting(false);
					return;
				}

				data = { keywords: selectedCampaignData.keywords.map((x) => x.keyword) };
				response = await GetDSEOTrendsExplore(data);
				break;
			case 'GetKeywordsForSite':
				data = { target: searchTerm };
				response = await GetKeywordsForSite(data);
				break;
			case 'GetKeywordsSearchVolume':
				data = {
					keywords : keywords.split(',')
					//location : selectedLocation.replace(/(^[,\s]+)|([,\s]+$)/g, '') //remove leading commas
				};
				response = await GetKeywordsSearchVolume(data);
				break;
			case 'GetApiStatus':
				response = await GetApiStatus();
				break;
			case 'GetAccountBalance':
				response = await GetAccountBalance();
				break;

			//Serp Api -----------------------
			case 'GetBusinessProfileDetails':
				data = { placeId: placeId };
				response = await GetBusinessProfileDetails(data);
				break;
			case 'GetBusinessProfileReviews':
				data = { placeId: placeId };
				response = await GetBusinessProfileReviews(data);
				break;
			case 'GetBusinessProfilePhotos':
				data = { placeId: placeId };
				response = await GetBusinessProfilePhotos(data);
				break;
			case 'GetGoogleSearchCitations':
				data = { searchTerm: searchTerm };
				response = await GetGoogleSearchResults(data);
				break;
			case 'GetGoogleSearchIndexedPagesByKeyword':
			case 'GetGoogleSearchAllIndexedPages':
				data = { searchTerm: searchTerm, numberOfResults: 100, page: page };
				response = await GetGoogleSearchResults(data);
				break;
			case 'GetAccountData':
				response = await GetAccountData();
				break;

			//Rapid Api -----------------------
			case 'GetDomainAuthority':
				data = { url: searchTerm };
				response = await GetDomainAuthority(data);
				break;
			case 'GetDomainWhoIs':
				data = { url: searchTerm };
				response = await GetDomainWhoIs(data);
				break;

			//Google Api
			case 'GetPageSpeed':
				data = { url: searchTerm };
				response = await GetPageSpeed(data);
				break;
		}

		if (!response || !response.success || !response.data) {
			let error = response.message || 'ERROR: Could not get API response';
			setApiResponse({ ERROR: error });
		} else {
			if (Array.isArray(response.data) && response.data.length === 0) {
				setTotalResults();
				setCurrentPage();
				setApiResponse(emptyApiResponse);
			} else {
				setTotalResults(response.totalResults);
				setCurrentPage(response.page);
				setPageSize(response.pageSize);
				setApiResponse(response.data);
			}
		}

		setIsSubmitting(false);
	};

	const handlePlaceSelected = async (place) => {
		if (place && place.address_components) {
			//get city, state and country full names
			let addressData = parseAddressFromGoogleProfile(place.address_components);

			if (addressData) {
				setSelectedLocation(`${addressData.city},${addressData.stateFull},${addressData.country}`);
			}
		}
	};

	const handleLocationChange = async (e) => {
		if (e) {
			setSelectedLocation(e.target.value);
		}
	};

	return (
		<div className="report-api-container">
			<div className="grid-tool-left-container">
				<div className="m-3">
					<h4 className="mb-3 py-2 border-bottom">API Methods</h4>
					<div className="accordion api-methods-accordion border" id="accordionApiMethods">
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#accordionItemDataForSeo"
									aria-expanded="true"
									aria-controls="accordionItemDataForSeo"
								>
									DataForSEO API
								</button>
							</h2>
							<div
								id="accordionItemDataForSeo"
								className="accordion-collapse collapse show"
								data-bs-parent="#accordionApiMethods"
							>
								<div className="accordion-body">
									{dataForSeoApiMethods.map((apiMethod, index) => (
										<button
											key={index}
											className={`btn w-100 text-start btn-api-method ${selectedApiMethod &&
											apiMethod.value === selectedApiMethod.value
												? 'btn-primary'
												: 'btn-link'}`}
											onClick={() => handleSelectApiMethod(apiMethod)}
										>
											{apiMethod.label}
										</button>
									))}
								</div>
							</div>
						</div>
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#accordionItemSerp"
									aria-expanded="false"
									aria-controls="accordionItemSerp"
								>
									Serp API
								</button>
							</h2>
							<div
								id="accordionItemSerp"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionSerp"
							>
								<div className="accordion-body">
									{serpApiMethods.map((apiMethod, index) => (
										<button
											key={index}
											className={`btn w-100 text-start btn-api-method ${selectedApiMethod &&
											apiMethod.value === selectedApiMethod.value
												? 'btn-primary'
												: 'btn-link'}`}
											onClick={() => handleSelectApiMethod(apiMethod)}
										>
											{apiMethod.label}
										</button>
									))}
								</div>
							</div>
						</div>
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#accordionItemRapid"
									aria-expanded="false"
									aria-controls="accordionItemRapid"
								>
									Rapid API
								</button>
							</h2>
							<div
								id="accordionItemRapid"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionRapid"
							>
								<div className="accordion-body">
									{rapidApiMethods.map((apiMethod, index) => (
										<button
											key={index}
											className={`btn w-100 text-start btn-api-method ${selectedApiMethod &&
											apiMethod.value === selectedApiMethod.value
												? 'btn-primary'
												: 'btn-link'}`}
											onClick={() => handleSelectApiMethod(apiMethod)}
										>
											{apiMethod.label}
										</button>
									))}
								</div>
							</div>
						</div>
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#accordionItemGoogle"
									aria-expanded="false"
									aria-controls="accordionItemGoogle"
								>
									Google API
								</button>
							</h2>
							<div
								id="accordionItemGoogle"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionGoogle"
							>
								<div className="accordion-body">
									{googleApiMethods.map((apiMethod, index) => (
										<button
											key={index}
											className={`btn w-100 text-start btn-api-method ${selectedApiMethod &&
											apiMethod.value === selectedApiMethod.value
												? 'btn-primary'
												: 'btn-link'}`}
											onClick={() => handleSelectApiMethod(apiMethod)}
										>
											{apiMethod.label}
										</button>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<MainAppContentArea>
				<div className="row">
					<div className="col-12">
						<PageHeadline headline={methodTitle} />
					</div>
				</div>

				<div className="main-content-container mt-0">
					<div className="main-content-inner-container">
						{!selectedApiMethod && (
							<div className="row p-3">
								<div className="col-12">Select API method</div>
							</div>
						)}

						{selectedApiMethod && (
							<form className="row p-3">
								<h5 className="mb-4">{selectedApiMethod.label}</h5>

								{selectedApiMethod.showCampaign && (
									<Fragment>
										<label htmlFor="campaigns" className="form-label col-12">
											Campaign:
										</label>
										<div className="col-12 mb-3">
											{campaigns && (
												<Select
													options={campaigns}
													value={selectedCampaign}
													onChange={async (e) => handleCampaignSelect(e)}
													classNamePrefix="campaigns_select"
													//isClearable={true}
													// required
												/>
											)}
										</div>
									</Fragment>
								)}

								{selectedApiMethod.showPlaceId && (
									<Fragment>
										<label htmlFor="placeId" className="form-label col-12">
											Place Id:
										</label>
										<div className="col-12 mb-3">
											<input
												type="text"
												className="form-control"
												name="placeId"
												value={placeId}
												onChange={(e) => setPlaceId(e.target.value)}
												disabled={isSubmitting}
											/>
										</div>
									</Fragment>
								)}

								{selectedApiMethod.showLocations && (
									<Fragment>
										<label htmlFor="locationName" className="form-label col-12">
											Location:
										</label>
										<div className="col-12 mb-3">
											<GoogleBusinessAutoComplete
												onPlaceSelected={handlePlaceSelected}
												//defaultPlace={approvedOrder ? approvedOrder.streetAddress1 : null}
												onChange={(e) => handleLocationChange(e)}
											/>
										</div>
									</Fragment>
								)}

								{selectedApiMethod.showDSEOOnPageParams && (
									<Fragment>
										<label htmlFor="onPageTarget" className="form-label col-12">
											Target Domain:
										</label>
										<div className="col-12 mb-3">
											<input
												type="text"
												className="form-control"
												name="onPageTarget"
												value={onPageTarget}
												onChange={(e) => setOnPageTarget(e.target.value)}
												disabled={isSubmitting}
												placeholder="Domain name without https:// and www."
												required
											/>
										</div>
										<label htmlFor="onPageMaxCrawlPages" className="form-label col-12">
											Max Crawl Pages:
										</label>
										<div className="col-12 mb-3">
											<input
												type="number"
												className="form-control"
												name="onPageMaxCrawlPages"
												value={onPageMaxCrawlPages}
												onChange={(e) => setOnPageMaxCrawlPages(e.target.value)}
												disabled={isSubmitting}
												min={1}
												max={20}
												placeholder="Number of pages to crawl"
												required
											/>
										</div>
										<label htmlFor="onPageStartUrl" className="form-label col-12">
											Start Url:
										</label>
										<div className="col-12 mb-3">
											<input
												type="text"
												className="form-control"
												name="onPageStartUrl"
												value={onPageStartUrl}
												onChange={(e) => setOnPageStartUrl(e.target.value)}
												disabled={isSubmitting}
												placeholder="First url to crawl (absolute url)"
											/>
										</div>
									</Fragment>
								)}

								{selectedApiMethod.showSearchTerm && (
									<Fragment>
										<label htmlFor="searchTerm" className="form-label col-12">
											{`${searchTermLabel}:`}
										</label>
										<div className="col-12 mb-3">
											<input
												type="text"
												className="form-control"
												name="searchTerm"
												value={searchTerm}
												onChange={(e) => setSearchTerm(e.target.value)}
												disabled={isSubmitting}
											/>
										</div>
									</Fragment>
								)}

								{selectedApiMethod.showKeywords && (
									<Fragment>
										<label htmlFor="keywords" className="form-label col-12">
											Keywords
										</label>
										<div className="col-12 mb-3">
											<input
												type="text"
												className="form-control"
												name="keywords"
												value={keywords}
												onChange={(e) => setKeywords(e.target.value)}
												disabled={isSubmitting}
											/>
										</div>
									</Fragment>
								)}

								<div className="col-12 mb-3">
									<button
										className="btn btn-primary"
										type="button"
										onClick={(e) => handleSubmit(e, 1)}
										disabled={isSubmitting}
									>
										{isSubmitting ? (
											<Fragment>
												<span className="spinner-border m-0 me-2" />
												<span>Submitting...</span>
											</Fragment>
										) : (
											<span>Submit</span>
										)}
									</button>
								</div>
								<div className="col-12 mb-2">
									<hr className="col-12 my-2" />
								</div>
								<label htmlFor="apiResponse" className="form-label col-12">
									Response:
								</label>
								<div className="col-12 mb-3">
									<div className="json-viewer form-control">
										{isSubmitting ? (
											<div>
												<Spinner />
											</div>
										) : (
											<ReactJsonView
												src={apiResponse || {}}
												name={false}
												collapsed={1}
												displayDataTypes={false}
												//onAdd={false}
											/>
										)}
									</div>
								</div>

								{apiResponse &&
								Array.isArray(apiResponse) &&
								apiResponse.length > 0 && (
									<div className="col-12 mb-3">
										<Pagination
											className="pagination-bar justify-content-center"
											currentPage={currentPage}
											totalCount={totalResults}
											pageSize={pageSize}
											onPageChange={(page) => {
												setCurrentPage(page);
												handleSubmit(null, page);
											}}
										/>
									</div>
								)}
							</form>
						)}
					</div>
				</div>
			</MainAppContentArea>
		</div>
	);
};

export default NewReportApiTest;
