import AutoComplete from 'react-google-autocomplete';

const GoogleBusinessAutoComplete = ({ onPlaceSelected, defaultPlace = null, onChange }) => {
	const handlePlaceSelected = (place) => {
		if (place) {
			place.lat = place.geometry.location.lat();
			place.lng = place.geometry.location.lng();

			if (onPlaceSelected) {
				onPlaceSelected(place);
			}
		}
	};

	const handleChange = (e) => {
		if (onChange) {
			onChange(e);
		}
	};

	return (
		<AutoComplete
			apiKey="AIzaSyBukTa2y70Uz3uRpXqhlmZdkBB3WAhxmvg"
			onPlaceSelected={(place) => handlePlaceSelected(place)}
			inputAutocompleteValue={defaultPlace}
			options={{
				callback : 'mapInitialized',
				types    : [],
				fields   : [
					'address_component',
					'adr_address',
					'business_status',
					'formatted_address',
					'geometry',
					'icon',
					'icon_mask_base_uri',
					'icon_background_color',
					'name',
					'photo',
					'place_id',
					'plus_code',
					'type',
					'url',
					'utc_offset_minutes',
					'vicinity',
					'wheelchair_accessible_entrance',
					'current_opening_hours',
					'formatted_phone_number',
					'international_phone_number',
					'website',
					'reviews',
					'user_ratings_total',
					'rating'
				]
			}}
			className="form-control"
			onChange={(e) => handleChange(e)}
		/>
	);
};

export default GoogleBusinessAutoComplete;
