import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Spinner from '../../../components/spinner/Spinner';
import './../businessProfileConnect/BusinessProfileConnect.css';
import { Fragment } from 'react';
import './GridReportTool.css';
import GridMapV5 from '../../../components/gridReport/v5/gridMap/GridMapV5';
import { toast } from 'react-toastify';
import { GenerateSEOReportDataGrid, GetReportSettings } from '../../../Services';
import { useStateValue } from '../../../StateProvider';
import { GenerateReportData, GetReportData } from '../../../ReportAPI';
import moment from 'moment';

const GridReportToolNewDemo = () => {
	const [ { user, host }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isLoadingMap, setIsLoadingMap ] = useState(false);
	const [ isGenerating, setIsGenerating ] = useState(false);
	const [ isGeneratingGrid, setIsGeneratingGrid ] = useState(false);
	const [ gridData, setGridData ] = useState();
	const [ reportData, setReportData ] = useState({
		gridData        : {},
		businessData    : {},
		competitor1Data : {},
		competitor2Data : {},
		competitor3Data : {}
	});
	const categories = [ 'business', 'competitor1', 'competitor2', 'competitor3' ];
	const [ reportSettings, setReportSettings ] = useState();
	const [ businessPlaceId, setBusinessPlaceId ] = useState();
	const [ keyword, setKeyword ] = useState();
	const [ competitor1PlaceId, setCompetitor1PlaceId ] = useState();
	const [ competitor2PlaceId, setCompetitor2PlaceId ] = useState();
	const [ competitor3PlaceId, setCompetitor3PlaceId ] = useState();
	const { placeId, name } = useParams();
	const gridMapContainerRef = useRef();

	useEffect(() => {
		getReportSettings();
	}, []);

	useEffect(() => {
		if (placeId && name) {
			getReportData();
		}
	}, []);

	useEffect(
		() => {
			if (window.mapReady) {
				setIsLoadingMap(false);
			}
		},
		[ window.mapReady ]
	);

	const getReportSettings = async () => {
		let response = await GetReportSettings();

		if (response && response.data) {
			setReportSettings(response.data);
		}
	};

	const getReportData = async () => {
		let response = await GetReportData(placeId, name);

		if (response && response.data) {
			let tempReportData = response.data;

			setReportData(tempReportData);

			if (tempReportData.inputData) {
				setBusinessPlaceId(tempReportData.inputData.businessPlaceId);
				setKeyword(tempReportData.inputData.keyword);
				setCompetitor1PlaceId(tempReportData.inputData.competitor1PlaceId);
				setCompetitor2PlaceId(tempReportData.inputData.competitor2PlaceId);
				setCompetitor3PlaceId(tempReportData.inputData.competitor3PlaceId);
			}
		}
	};

	const generateReport = async (e) => {
		e.preventDefault();

		if (!businessPlaceId || businessPlaceId.trim() === '') {
			toast.error('Business Place Id is required');
			return;
		}

		// if (!keyword || keyword.trim() === '') {
		// 	toast.error('Keyword is required');
		// 	return;
		// }

		if (!competitor1PlaceId || competitor1PlaceId.trim() === '') {
			toast.error('Competitor 1 Place Id is required');
			return;
		}

		if (!competitor2PlaceId || competitor2PlaceId.trim() === '') {
			toast.error('Competitor 2 Place Id is required');
			return;
		}

		if (!competitor3PlaceId || competitor3PlaceId.trim() === '') {
			toast.error('Competitor 3 Place Id is required');
			return;
		}

		setIsGenerating(true);
		setIsLoading(true);

		//create full report
		let data = {
			businessPlaceId    : businessPlaceId,
			keyword            : keyword,
			competitor1PlaceId : competitor1PlaceId,
			competitor2PlaceId : competitor2PlaceId,
			competitor3PlaceId : competitor3PlaceId
		};

		const reportResponse = await GenerateReportData(data);

		if (reportResponse.success) {
			let tempReport = reportResponse.data;

			if (tempReport.businessData) {
				// let gridPoints = 7;
				// let pointDistance = 1; //parseFloat(areaRadius * 2 / (gridPoints - 1));
				// //generate grid report
				// let gridData = {
				// 	salesUserId   : user && user.isSalesUser ? user.id : null,
				// 	campaignId    : null,
				// 	keywords      : [ keyword ],
				// 	lat           : tempReport.businessData.lat,
				// 	lng           : tempReport.businessData.lng,
				// 	googlePlaceId : businessPlaceId,
				// 	gridPoints    : gridPoints,
				// 	pointDistance : pointDistance,
				// 	gridApiType   : 'dseo'
				// };
				// const gridReportResponse = await GenerateSEOReportDataGrid(gridData);
				// if (gridReportResponse.success) {
				// 	tempReport.gridData = gridReportResponse;
				// }
			}

			setReportData({
				...reportData,
				tempReport
			});

			if (tempReport.reportName) {
				window.location.href = window.location.href.replace(name, tempReport.reportName);
			}
		} else {
			toast.error(reportResponse.message);
			console.error(reportResponse);
		}

		setIsGenerating(false);
		setIsLoading(false);

		// //first get business profile info in order to create grid map
		// let businessProfileResponse = await GetBusinessProfileDetails({ placeId: businessPlaceId });

		// if (businessProfileResponse.success) {

		// }
	};

	return (
		<div className="app-container">
			<div className="grid-tool-left-container">
				<div className="row p-3">
					<form className="mt-3">
						<div className="col-12 mb-3">
							<label className="form-label">Business Place Id:</label>
							<input
								type="text"
								name="businessPlaceId"
								className="form-control"
								value={businessPlaceId || ''}
								onChange={(e) => setBusinessPlaceId(e.target.value)}
								disabled={isGenerating || isLoadingMap}
								required
							/>
						</div>
						<div className="col-12 mb-3">
							<label className="form-label">Keyword:</label>
							<input
								type="text"
								name="keyword"
								className="form-control"
								value={keyword || ''}
								onChange={(e) => setKeyword(e.target.value)}
								disabled={isGenerating || isLoadingMap}
							/>
						</div>
						<div className="col-12 mb-3">
							<label className="form-label" htmlFor="competitor1PlaceId">
								Competitor 1 Place Id:
							</label>
							<input
								type="text"
								name="competitor1PlaceId"
								className="form-control"
								value={competitor1PlaceId || ''}
								onChange={(e) => setCompetitor1PlaceId(e.target.value)}
								disabled={isGenerating || isLoadingMap}
								required
							/>
						</div>
						<div className="col-12 mb-3">
							<label className="form-label" htmlFor="competitor2PlaceId">
								Competitor 2 Place Id:
							</label>
							<input
								type="text"
								name="competitor2PlaceId"
								className="form-control"
								value={competitor2PlaceId || ''}
								onChange={(e) => setCompetitor2PlaceId(e.target.value)}
								disabled={isGenerating || isLoadingMap}
								required
							/>
						</div>
						<div className="col-12 mb-3">
							<label className="form-label" htmlFor="competitor3PlaceId">
								Competitor 3 Place Id:
							</label>
							<input
								type="text"
								name="competitor3PlaceId"
								className="form-control"
								value={competitor3PlaceId || ''}
								onChange={(e) => setCompetitor3PlaceId(e.target.value)}
								disabled={isGenerating || isLoadingMap}
								required
							/>
						</div>
						<div className="col-12">
							<button
								className="btn btn-primary"
								onClick={(e) => generateReport(e)}
								disabled={isGenerating || isLoadingMap}
							>
								{isGenerating ? (
									<Fragment>
										<span className="spinner-border m-0 me-2" />
										<span>Generating Report...</span>
									</Fragment>
								) : (
									<span>Generate Report</span>
								)}
							</button>
						</div>
					</form>
				</div>
			</div>

			<div className="main-content-inner-container grid-tool-content-container p-2">
				<div className="map-wrapper h-100 border position-relative" ref={gridMapContainerRef}>
					{(isGenerating || isLoadingMap) && (
						<div className="loading-map">
							<Spinner />
						</div>
					)}

					<ul className="nav nav-tabs" id="tabReport" role="tablist">
						<li className="nav-item" role="presentation" key="audit-report">
							<button
								className="nav-link active"
								id="audit-report-tab"
								data-bs-toggle="tab"
								data-bs-target="#audit-report-tab-pane"
								type="button"
								role="tab"
								aria-controls="audit-report-tab-pane"
								aria-selected="true"
							>
								Audit
							</button>
						</li>
						<li className="nav-item" role="presentation" key="grid-report">
							<button
								className="nav-link"
								id="grid-report-tab"
								data-bs-toggle="tab"
								data-bs-target="#grid-report-tab-pane"
								type="button"
								role="tab"
								aria-controls="grid-report-tab-pane"
								aria-selected="true"
							>
								Grid
							</button>
						</li>
					</ul>
					<div className="tab-content" id="tabReportContent">
						<div
							className="tab-pane fade show active"
							id="audit-report-tab-pane"
							role="tabpanel"
							aria-labelledby="audit-report-tab"
							tabIndex="0"
						>
							<div className="audit-data-container p-3">
								<table className="items-list audit-table table border table-responsive">
									<thead>
										<tr>
											<th scope="col" className="description" />
											<th className="business" scope="col">
												Your Business
											</th>
											<th className="business competitor" scope="col">
												Competitor 1
											</th>
											<th className="business competitor" scope="col">
												Competitor 2
											</th>
											<th className="business competitor" scope="col">
												Competitor 3
											</th>
										</tr>
									</thead>
									{reportData.businessData && reportData.businessData.profile ? (
										<tbody>
											<tr>
												<td className="description">Profile % Complete (Checklist)</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="border border-secondary border-2 p-0" colSpan={5} />
											</tr>
											<tr>
												<td colSpan={5} className="separator-row">
													<strong>Keywords In:</strong>
												</td>
											</tr>
											<tr>
												<td className="description">Profile Categories</td>
												{categories.map((cat) => (
													<td
														key={cat}
														className={`business ${cat === 'business' ? '' : 'competitor'}`}
													>
														{reportData[cat + 'Data'].profile &&
														reportData[cat + 'Data'].profile.CategoryIds &&
														reportData[cat + 'Data'].profile.CategoryIds.length > 0 ? (
															'Yes'
														) : (
															'No'
														)}
													</td>
												))}
											</tr>
											<tr>
												<td className="description">Profile Description</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'].profile &&
														reportData[cat + 'Data'].profile.Description ? (
															'Yes'
														) : (
															'No'
														)}
													</td>
												))}
											</tr>
											<tr>
												<td className="description">Profile Reviews (Last 20)</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'].reviews &&
														reportData[cat + 'Data'].reviews.length > 0 ? (
															'Yes'
														) : (
															'No'
														)}
													</td>
												))}
											</tr>
											<tr>
												<td className="description">Profile Posts</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'].posts &&
														reportData[cat + 'Data'].posts.results &&
														reportData[cat + 'Data'].posts.results.length > 0 ? (
															'Yes'
														) : (
															'No'
														)}
													</td>
												))}
											</tr>
											<tr>
												<td className="description">Profile Q&A</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'].qa &&
														reportData[cat + 'Data'].qa.length > 0 ? (
															'Yes'
														) : (
															'No'
														)}
													</td>
												))}
											</tr>
											<tr>
												<td className="description">Website Landing Page</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Website Pages Indexed</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="border border-secondary border-2 p-0" colSpan={5} />
											</tr>
											<tr>
												<td className="description">Domain Age</td>
												{categories.map((cat) => {
													let dateNow = moment(new Date()); //'DD/MM/YYYY HH:mm:ss'
													let elapsedYears =
														reportData[cat + 'Data'].whois &&
														reportData[cat + 'Data'].whois.CreatedDate
															? dateNow.diff(
																	reportData[cat + 'Data'].whois.CreatedDate,
																	'years'
																)
															: '';

													return (
														<td key={cat} className="business">
															{elapsedYears ? (
																`${elapsedYears} ${elapsedYears === 1
																	? 'Year'
																	: 'Years'}`
															) : (
																''
															)}
														</td>
													);
												})}
											</tr>
											<tr>
												<td className="description">Total Indexed Pages</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'].indexedPages &&
														reportData[cat + 'Data'].indexedPages.total ? (
															reportData[cat + 'Data'].indexedPages.total
														) : (
															''
														)}
													</td>
												))}
											</tr>
											<tr>
												<td className="description">Total Citations</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'].citations &&
														reportData[cat + 'Data'].citations.total ? (
															reportData[cat + 'Data'].citations.total
														) : (
															''
														)}
													</td>
												))}
											</tr>
											<tr>
												<td className="description">Backlinks</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Review Score</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Total Posts</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'].posts &&
														reportData[cat + 'Data'].posts.total ? (
															reportData[cat + 'Data'].posts.total
														) : (
															''
														)}
													</td>
												))}
											</tr>
											<tr>
												<td className="description">Total Q&A</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Total Images</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'].profile &&
														reportData[cat + 'Data'].profile.TotalPhotos ? (
															reportData[cat + 'Data'].profile.TotalPhotos
														) : (
															0
														)}
													</td>
												))}
											</tr>
											<tr>
												<td className="border border-secondary border-2 p-0" colSpan={5} />
											</tr>
											<tr>
												<td className="description">Total Reviews</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'].profile &&
														reportData[cat + 'Data'].profile.Rating &&
														reportData[cat + 'Data'].profile.Rating.votes_count ? (
															reportData[cat + 'Data'].profile.Rating.votes_count
														) : (
															0
														)}
													</td>
												))}
											</tr>
											<tr>
												<td className="description">Average Rating</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'].profile &&
														reportData[cat + 'Data'].profile.Rating &&
														reportData[cat + 'Data'].profile.Rating.Value ? (
															reportData[cat + 'Data'].profile.Rating.Value
														) : (
															0
														)}
													</td>
												))}
											</tr>
											<tr>
												<td colSpan={5} className="separator-row">
													<strong>Last 30 Days</strong>
												</td>
											</tr>
											<tr>
												<td className="description">Total Reviews</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Average Rating</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Average Words Per Review</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Have Replies</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Local Guide Reviews</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Negative Reviews</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Negative Reviews Have Replies</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td colSpan={5} className="separator-row">
													<strong>Last 20 Reviews</strong>
												</td>
											</tr>
											<tr>
												<td className="description">Average Rating</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Average Words Per Review</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Have Replies</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Local Guide Reviews</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Negative Reviews</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Negative Reviews Have Replies</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
											<tr>
												<td className="description">Most Relevant Review Age (Sort By Most)</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>
										</tbody>
									) : (
										<tbody>
											<tr>
												<td colSpan={5}>
													<i>No Data</i>
												</td>
											</tr>
										</tbody>
									)}
								</table>
							</div>
						</div>
						<div
							className="tab-pane fade"
							id="grid-report-tab-pane"
							role="tabpanel"
							aria-labelledby="grid-report-tab"
							tabIndex="0"
						>
							{gridData && (
								<Fragment>
									<GridMapV5
										key="map-0"
										mapIdValue="report-map-0"
										gridData={gridData}
										placeId={gridData.googlePlaceId}
										isFullHeight={true}
										reportSettings={reportSettings}
									/>
								</Fragment>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GridReportToolNewDemo;
